@import './variables';

* {
    box-sizing: border-box; /* padding-ot (belső eltartaás) számolja bele a szélességbe (width-be) minden elemenél (*) */
    font-family: "myriad-pro", "Open Sans", Times, "Times New Roman", serif;
}

body {
    background-color: $default-bg;
    padding: 0;
    @media all and (min-width: 960px) {
        padding: 30px 0;
    }
    margin: 0;
    color: $text-dark;
}

@media print {
    body, * {
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
    }
}
